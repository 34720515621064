<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-22 09:52:48
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-26 16:48:40
 * @FilePath: /mediatom-web/src/components/CreativeForm/AdxFlowForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adx-flow-form-container">
    <!-- 屏蔽快应用 -->
    <a-form-model-item prop="shieldBudgetSwitch" label="屏蔽快应用">
      <a-switch size="small" :checked="formquery.shieldBudgetSwitch === 1" @click="handleChangeSwitch('shieldBudgetSwitch')"></a-switch>
    </a-form-model-item>
  </div>
</template>

<script>
import moment from 'moment'
import mixDate from '@/mixins/initDate'
export default {
  name: 'GlobalAdxFlowForm',
  mixins: [mixDate],
  data () {
    return {}
  },
  created () {
    // 预算屏蔽
    this.$set(this.formquery, 'shieldBudgetSwitch', this.value.shieldBudgetSwitch || 0)
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    moment,
    handleChangeSwitch (key) {
      this.formquery[key] = +!this.formquery[key]
    }
  }
}
</script>

<style lang="less" scoped>
</style>
